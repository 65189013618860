<div class="d-flex p-3 row" *ngIf="role !== 'admin'">
  <div class="col-md-10 col-8 pr-3 salary" id="salary">
    รายได้สุทธิของคุณ {{ salary | number: '1.2-2' }} บาท
    <br>บันทึก : {{ note }}
  </div>
  <div class="flex-shrink-1 col-4 col-md-2">
      <button id="btn-add" *ngIf="userFlag === 'Y'" type="button" class="btn btn-blue form-control" (click)="openTemplateModal()">
        Add Income
      </button>
      <button id="btn-edit" *ngIf="userFlag === 'N'" type="button" class="btn btn-red form-control" (click)="openTemplateModal()">
        Edit Income
      </button>
  </div>
</div>

<ng-template #templateModal>
  <div>
    <app-modal-income (addIncomeEmit)="addIncomeEmit($event)" (closeModalEmit)="closeModalEvent($event)"
      [addIncomeData]="addIncomeResponse" [typeUser]="typeUser" [typeVat]="typeVat"></app-modal-income>
  </div>
</ng-template>