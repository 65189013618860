<div class="table-responsive" [formGroup]="tableListForm">
  <table class="table table-hover" *ngIf="ListData; else ContentLoader">
    <thead>
      <tr>
        <th class="text-start align-middle">Name</th>
        <th class="text-start align-middle">E-mail</th>
        <th class="text-center align-middle">Start Date</th>
        <th class="text-start align-middle">Phone Number</th>
        <th class="text-center align-middle">Submit Date</th>
        <th class="text-center align-middle">Work Day</th>
        <th class="text-center align-middle">Working Hours</th>
        <th class="text-center align-middle status-sort" (click)="setOrder()">
          Status <i [ngClass]="swapArrowIconSort ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-start">{{ getName(ListData[0].user) }}</td>
        <td class="text-start">{{ checkValue(ListData[0].user?.email) }}</td>
        <td class="text-center">{{ ListData[0]?.user?.startDate | date: "dd/MM/yyyy" || '-' }}</td>
        <td class="text-start">{{ checkValue(ListData[0].user?.phone) }}</td>
        <td class="text-center">
          {{
          ListData[0]?.submitDate == ""
          ? "-"
          : (ListData[0]?.submitDate | date: "dd/MM/yyyy HH:mm:ss")
          }}
        </td>
        <td class="text-center">{{ checkValue(ListData[0].workDate) }}</td>
        <td class="text-center">{{ checkValue(ListData[0].workingHours ? ListData[0].workingHours : '') }}</td>
        <td class="text-center" appStatusHighlight [Status]="ListData[0]?.status">
          {{ ListData[0]?.status === "Y" ? "Submitted" : "Not submitted" }}
        </td>
      </tr>
      <tr formArrayName="items"
        *ngFor="let listData of ListData | slice : 1 : ListData.length | orderBy: order:swapArrowIconSort:'case-insensitive'; let i = index">
        <td class="text-start">{{ getName(listData.user) }}</td>
        <td class="text-start">{{ checkValue(listData.user?.email) }}</td>
        <td class="text-center">{{ listData.user?.startDate | date: "dd/MM/yyyy" || '-' }}</td>
        <td class="text-start">{{ checkValue(listData.user?.phone) }}</td>
        <td class="text-center">
          {{
            listData?.submitDate == ""
            ? "-"
            : (listData?.submitDate | date: "dd/MM/yyyy HH:mm:ss")
          }}
        </td>
        <td class="text-center">{{ checkValue(listData.workDate) }}</td>
        <td class="text-center">{{ checkValue(listData.workingHours ? listData.workingHours : '') }}</td>
        <td class="text-center" appStatusHighlight [Status]="listData?.status">
          {{ listData?.status === "Y" ? "Submitted" : "Not submitted" }}
        </td>
    
      </tr>

    </tbody>
  </table>
</div>
<ng-template #ContentLoader>
  <div *ngIf="isNoData" class="text-center">No data.</div>
  <list-content-loader *ngIf="isShowLoading" [width]="300" [speed]="1" [secondaryColor]="'#F0F0F0'"
    [primaryColor]="'#A1A1A1'"></list-content-loader>
</ng-template>



<ng-template #templateModal>
  <div class="modal-header">
    <span>บันทึกสถานะ ใบทวิ 50</span>
  </div>
  <div class="modal-body mx-sm-5">
    <span>ท่านต้องการบันทึกหรือไม่</span>
  </div>
  <div class="modal-footer">
    <button id="btn-submit" type="button" class="btn btn-blue" (click)="updateAllUser()">ยืนยัน</button>
    <button type="button" class="btn btn-green" (click)="modalRef.hide()">ยกเลิก</button>
  </div>
</ng-template>