<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="closeModal()">
  </button>
</div>

<ng-container *ngIf="!addIncomeAlready">
    <div class="modal-body mx-sm-5">
        <div class="py-2 bg-color">
            <div class="text-blue">
                <strong>ตัวอย่าง</strong>
            </div>
            <div class="text-blue font-middle"> - น้อง A ทำงานพิเศษ ชม. ละ 100 บาท ทำไป 20 ชม. </div>
        </div>
        <form [formGroup]="fg">
            <div class="row my-2">
                <div class="form-group col-6">
                    <label>รายได้พิเศษ : </label>
                    <div class="input-group">
                        <input id="specialIncome" type="text" style="text-align:right" class="form-control" formControlName="specialIncome" (keyup)="inputIncomeAmount()">
                        <div class="input-group-append">
                            <span class="input-group-text">บาท/ชม.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label>จำนวนชั่วโมง : </label>
                    <div class="input-group">
                        <input id="workingHours" type="text" style="text-align:right" class="form-control" formControlName="workingHours" (keyup)="inputWorkingHours()">
                        <div class="input-group-append">
                            <span class="input-group-text">ชม.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-2 bg-color">
                <div class="text-blue">
                    <strong>ตัวอย่าง</strong>
                </div>
                <div class="text-blue font-middle"> - น้อง A จะกรอกจำนวนวันที่ทำงานต้องไปใส่ รายได้รายวันที่ หน้า Profile ก่อนนะ
                </div>
            </div>
            <div class="form-group my-2">
                <label>จำนวนวันที่ทำงาน : </label>
                <div class="input-group">
                    <input id="workDate" type="text" style="text-align:right" class="form-control" formControlName="workDate" (keyup)="inputWorkDate()">
                    <div class="input-group-append">
                        <span class="input-group-text">วัน</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>บันทึก :</label>
                <textarea id="note" type="text" class="form-control" formControlName="note"></textarea>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="addIncomeAlready">
    <div class="modal-body mx-sm-5">

        <div class="row">
            <div class="col-12 content-container">
                <div class="row row mb-3">
                    <div class="col col-2"></div>
                    <div class="col col-10 detail">
                        <strong>เงินที่คุณจะได้รับ</strong>
                    </div>

                </div>
                <div class="row mb-2">
                    <div class="col col-2"></div>
                    <div class="col col-3">รายได้สุทธิ :</div>
                    <div class="col col-5 text-end" id="addIncomeTotalIncome">{{ addIncomeData.netDailyIncome | number: '1.2-2'}}
                        <span>บาท</span>
                    </div>
                    <div class="col col-2"></div>
                </div>

                <div class="row mb-2" *ngIf="isVat()">
                    <div class="col col-2"></div>
                    <div class="col col-3">Vat 7% :</div>
                    <div class="col col-5 text-end" id="addIncomeVat">{{ vatPrimary | number: '1.2-2' }}
                        <span>บาท</span>
                    </div>
                    <div class="col col-2"></div>
                </div>
                <div class="row mb-2">
                    <div class="col col-2"></div>
                    <div class="col col-3">
                        <div *ngIf="role == 'corporate'">
                            Wht 3% :
                        </div>
                        <div *ngIf="role == 'individual'">
                            Wht 3% :
                        </div>
                    </div>
                    <div class="col col-5 text-end" id="addIncomeWht">{{ whtPrimary | number: '1.2-2' }}
                        <span>บาท</span>
                    </div>
                    <div class="col col-2"></div>
                </div>

                <hr>

                <div class="row mb-2">
                    <div class="col col-2"></div>
                    <div class="col col-3">รายได้พิเศษ :</div>
                    <div class="col col-5 text-end" id="addIncomeSpecialIncome">{{ addIncomeData.netSpecialIncome | number: '1.2-2'}}
                        <span>บาท</span>
                    </div>
                    <div class="col col-2"></div>
                </div>

                <div class="row mb-2" *ngIf="isVat()">
                    <div class="col col-2"></div>
                    <div class="col col-3">Vat 7% :</div>
                    <div class="col col-5 text-end" id="addIncomeVat">{{ vatSpecial | number: '1.2-2' }}
                        <span>บาท</span>
                    </div>
                    <div class="col col-2"></div>
                </div>
                <div class="row mb-2">
                    <div class="col col-2"></div>

                    <div class="col col-3">
                        <div *ngIf="role == 'corporate'">
                            Wht 3% :
                        </div>
                        <div *ngIf="role == 'individual'">
                            Wht 3% :
                        </div>
                    </div>
                    <div class="col col-5 text-end" id="addIncomeWht">{{ whtSpecial | number: '1.2-2' }}
                        <span>บาท</span>
                    </div>
                    <div class="col col-2"></div>
                </div>
                <div class="row mb-2">
                    <div class="col col-2"></div>
                    <div class="col col-3 border-total">เงินที่ได้รับ :</div>
                    <div class="col col-5 border-total text-end font-weight-bold" id="addIncomeNetIncome">{{ addIncomeData.totalIncome | number: '1.2-2' }}
                        <span>บาท</span>
                    </div>
                    <div class="col col-2"></div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div class="modal-footer">
    <ng-container *ngIf="!addIncomeAlready">
        <button id="btn-submit" type="button" class="btn btn-blue" (click)="onSubmit()" [disabled]="disableButton()">Submit</button>
    </ng-container>
    <ng-container *ngIf="addIncomeAlready">
        <button id="btn-cancel" type="button" class="btn btn-gray" (click)="onCancel()">ไม่ OK</button>
        <button id="btn-confirm" type="button" class="btn btn-green" (click)="onConfirm()">OK</button>
    </ng-container>
</div>